'use client'

import { RiErrorWarningLine, RiLockLine } from '@remixicon/react'
import { Button } from 'antd'
import { useRouter } from 'next/navigation'
import { useTranslation } from 'react-i18next'

import useAuth from '@/hooks/useAuth'

const SignUpWarning: React.FC = () => {
  const router = useRouter()
  const { t } = useTranslation()
  const { user } = useAuth()

  if (user && (!user.subscribed || !user.signed)) {
    return (
      <div className='mx-auto mb-8 flex flex-col items-end gap-2 rounded-lg bg-warning p-4 text-left text-on-warning sm:max-w-[38em] dark:bg-dark-warning dark:text-dark-on-warning'>
        <div className='flex items-center gap-2'>
          <RiErrorWarningLine className='size-12' />
          <h2 className='text-base'>{t('sign-up-warning-complete-info')}</h2>
        </div>
        <div className='flex items-center gap-2'>
          <Button type='primary' onClick={() => router.push('/signup')}>
            {t('sign-up-warning-complete')}
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className='absolute left-1/2 top-1/2 z-10 mx-auto mb-8 flex w-full -translate-x-1/2 -translate-y-1/2 flex-col items-center justify-center gap-2 rounded-lg bg-warning p-2 text-left text-on-warning sm:w-auto sm:max-w-[38em] sm:p-4 dark:bg-dark-warning dark:text-dark-on-warning'>
      <div className='flex items-center gap-2'>
        <RiLockLine className='size-5 shrink-0' />
        <h2 className='text-base'>{t('sign-up-warning-info')}</h2>
      </div>
      <Button type='primary' onClick={() => router.push('/login')}>
        {t('sign-in')}
      </Button>
    </div>
  )
}

export default SignUpWarning
