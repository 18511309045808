'use client'

import { Work_Sans } from 'next/font/google'
import Link from 'next/link'

import useAuth from '@/hooks/useAuth'

import { cn } from '@/utils/clsx'

const workSans = Work_Sans({
  subsets: ['latin'],
  display: 'swap',
})

interface RiskyLogoProps {
  isChatEmpty?: boolean
}

const RiskyLogo: React.FC<RiskyLogoProps> = ({ isChatEmpty }) => {
  const { user } = useAuth()

  return (
    <Link
      href='/'
      className={cn(
        'absolute transition-all duration-300 flex flex-col items-center gap-3 work-sans-font',
        workSans.className,
        isChatEmpty
          ? 'top-9 sm:top-14 left-1/2 transform -translate-x-1/2'
          : user
            ? 'hidden'
            : 'top-1 left-4'
      )}
      style={{
        transitionProperty: 'top, left',
        transitionTimingFunction: 'ease-out, ease-out',
        transitionDuration: '0ms, 500ms',
      }}
    >
      <div
        className={cn(
          'font-bold tracking-wide',
          isChatEmpty ? 'text-4xl sm:text-6xl' : 'text-3xl'
        )}
      >
        Risky
        <span
          className={cn(
            'text-[#ff0000]',
            isChatEmpty ? 'text-5xl sm:text-7xl' : 'text-4xl'
          )}
        >
          .
        </span>
      </div>
      {isChatEmpty && (
        <div className='max-w-full whitespace-nowrap px-4 text-lg sm:text-2xl'>
          Your AI General Counsel
        </div>
      )}
    </Link>
  )
}

export default RiskyLogo
